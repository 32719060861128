
import { env } from "./env";
import AcademixThumb from "../src/assets/thumbnails/academix.png"
import BigAcademyThumb from "../src/assets/thumbnails/bigAcademy.jpg"

export const getThemeConfigData = () => {
  switch (env.environment) {
    case "dev":
      return {
        logo: "/images/logo/logo_academix.png",
        text: "Academix",
        color: "#3567DF",
        videoThumbnail:AcademixThumb
       
      };

    case "big_academy":
      return {
        logo: "/images/logo/logo_academix.png",
        text: "Big Academy",
        color: "#d0312eff",
         videoThumbnail:BigAcademyThumb
       
      };

   

      break;

    default:
      break;
  }
};





